import { createSlice } from "@reduxjs/toolkit";
import { notificationCreateAction, notificationDeleteAction, notificationEditAction, notificationListAction, resetNotificationtAction } from "../Action/NotificationAction";

const initialState = {
    notificationCreateStatus:"idle",
    notificationList:{},
    notificationEditStatus:"idle",
    notificationDeleteStatus:"idle"
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(notificationCreateAction.fulfilled, (state, action) => {
                state.notificationCreateStatus = 'succeeded'; 
            })     
        
            .addCase(resetNotificationtAction, () => initialState)

           
            .addCase(notificationListAction.fulfilled, (state, action) => {
                state.notificationList = action.payload.data
            })     


            .addCase(notificationEditAction.fulfilled, (state, action) => {
                state.notificationEditStatus = 'succeeded'; 
            })     


            .addCase(notificationDeleteAction.fulfilled, (state, action) => {
                state.notificationDeleteStatus = 'succeeded'; 
            })     
                  
                

                

    },
});

export default notificationSlice.reducer;









