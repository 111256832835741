import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const faqListAction = createAsyncThunk(
    'faq/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('faq/list')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const faqCreateAction = createAsyncThunk(
    'faq/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('faq/create', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const faqDeleteAction = createAsyncThunk(
    'faq/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`faq/delete?id=${reqBody?.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const faqUpdateAction = createAsyncThunk(
    'faq/update', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`faq/update`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })




