import { createSlice } from "@reduxjs/toolkit";

import {  LoginUserInfoAction, ManageAdminCreateAction, ManageAdminDeleteAction, ManageAdminEditAction, ManageAdminListAction, ManageAdmindetailsAction, SubAdminSuspendAction, resetManageAdminAction } from "../Action/ManageAdminAction";
const initialState ={
manageAdminList:{},
manageAdmindetail:{},
ManageAdminCreateStatus:"idle",
ManageAdminDeleteStatus:"idle",
ManageAdminEditStatus:"idle",
SubAdminSuspendStatus:"idle",
userInfo:{}
}


const manageAdminsSlice = createSlice({
    name: 'manageAdmin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
       
        .addCase(ManageAdminListAction.fulfilled, (state, action) => {
          state.manageAdminList=action?.payload?.data;
        })

        .addCase(ManageAdmindetailsAction.fulfilled, (state, action) => {
            state.manageAdmindetail=action?.payload?.data;
          })


        .addCase(ManageAdminCreateAction.fulfilled, (state, action) => {
          state.ManageAdminCreateStatus="success"
        })
        
        .addCase(ManageAdminDeleteAction.fulfilled, (state, action) => {
          state.ManageAdminDeleteStatus="success"
        })

        .addCase(ManageAdminEditAction.fulfilled, (state, action) => {
          state.ManageAdminEditStatus="success"
        })


        .addCase(SubAdminSuspendAction.fulfilled, (state, action) => {
          state.SubAdminSuspendStatus="success"
        })


        .addCase(LoginUserInfoAction.fulfilled, (state, action) => {
          state.userInfo=action?.payload?.data;
        })


        .addCase(resetManageAdminAction, () => initialState);
    },
  });
  
  export default manageAdminsSlice.reducer;

