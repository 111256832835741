import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const schoolcreateAction = createAsyncThunk(
    'school/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/school/create', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const multiTeachercreateAction = createAsyncThunk(
        'school/multiTeacher', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('/teacher/csv', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

        export const multiStudentcreateAction = createAsyncThunk(
            'school/multiStudent', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post('/student/csv', reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })



export const schoollistAction = createAsyncThunk(
    'school/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/school/list?search=${reqBody?.searchValue}&page=${reqBody?.page}&limit=${20}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

export const schooldeleteAction = createAsyncThunk(
    'school/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/school/delete?id=${reqBody?.id}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export const schooldetailsAction = createAsyncThunk(
    'school/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/school/details?id=${reqBody.id}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


    export const schoolTeacherIdListAction = createAsyncThunk(
        'school/techerlist', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/school/teacher?schoolId=${reqBody.id}&search=${reqBody?.searchValue}`)
    
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })



    export const schoolStudentIdListAction = createAsyncThunk(
        'school/studentlist', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/school/student?schoolId=${reqBody.id}&search=${reqBody?.searchValue}`)
    
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    

        export const schoolParentIdListAction = createAsyncThunk(
            'school/parentlist', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.get(`/school/parent?schoolId=${reqBody.id}&search=${reqBody?.searchValue}`)
        
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        
        
    
    



        
    

export const updateAction = createAsyncThunk(
    'school/update', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`/school/update`, reqBody)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export const suspendAction = createAsyncThunk(
    'school/suspend', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/school/suspend', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })
export const schoolResetPassAction = createAsyncThunk(
    'school/reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put(`school/reset-password`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })