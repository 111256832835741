import { createSlice } from "@reduxjs/toolkit";
import { multiStudentcreateAction, multiTeachercreateAction, schoolParentIdListAction, schoolResetPassAction, schoolStudentIdListAction, schoolTeacherIdListAction, schooldetailsAction, schoollistAction } from "../Action/schoolAction";
const initialState = {
  schoollist:{},
  schooldetails: {},
  schoolTeacherIdList:{},
  schoolStudentIdList:{},
  schoolParentIdList:{},
  schoolResetPassStatus: "idle",
  multiTeachercreateStatus:"idle",
  multiStudentcreateStatus:"idle"

}

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(schoollistAction.fulfilled, (state, action) => {
        state.schoollist = action?.payload?.data;
      })
      .addCase(schooldetailsAction.fulfilled, (state, action) => {
        state.schooldetails = action?.payload?.data;
      })
      .addCase(schoolResetPassAction.fulfilled, (state, action) => {
        state.schoolResetPassStatus = 'succeeded';
      })
     
      .addCase(schoolTeacherIdListAction.fulfilled, (state, action) => {
        state.schoolTeacherIdList = action?.payload?.data;
      })

      .addCase(schoolStudentIdListAction.fulfilled, (state, action) => {
        state.schoolStudentIdList = action?.payload?.data;
      })

      .addCase(schoolParentIdListAction.fulfilled, (state, action) => {
        state.schoolParentIdList = action?.payload?.data;
      })


      
      
      .addCase(multiTeachercreateAction.fulfilled, (state, action) => {
        state.multiTeachercreateStatus = 'succeeded';
      })



      .addCase(multiStudentcreateAction.fulfilled, (state, action) => {
        state.multiStudentcreateStatus = 'succeeded';
      })

      
  },
});

export default schoolSlice.reducer;