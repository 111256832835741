import { createSlice } from "@reduxjs/toolkit";
import { DashboardListAction, RegistrationGraphAction } from "../Action/DashBoardAction";


const initialState = {
    dashboardList:[],
    registrationGraph:[]
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(DashboardListAction.fulfilled, (state, action) => {
                state.dashboardList = action?.payload?.data;  
            })     
             
            .addCase(RegistrationGraphAction.fulfilled, (state, action) => {
                state.registrationGraph = action?.payload?.data;  
            })
             
    },
});

export default dashboardSlice.reducer;








