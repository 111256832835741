import axios from "axios";
const Api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  
    headers : {Authorization: "Bearer"+` ${localStorage.getItem("token")}`,}
  });
 
// Add a response interceptor
Api.interceptors.response.use(function (response) {


    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if(error.response.status == 401){
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  });


  export default Api;