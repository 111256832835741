// PublicRoutesSuspense.js
import React, { Suspense } from 'react';
import LoaderComponent from '../common/LoaderComponent';

const PublicRoutesSuspense = ({ children }) => (
  <Suspense fallback={
    <LoaderComponent />
  }>
    {children}
  </Suspense>
);

export default PublicRoutesSuspense;
