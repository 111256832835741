import { createSlice } from "@reduxjs/toolkit";
import { faqCreateAction,faqListAction } from "../Action/FaqAction";


const initialState = {
   faquestions:[],
   faqCreateStatus:"idle"
}

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(faqListAction.fulfilled, (state, action) => {
                state.faquestions = action?.payload?.data;  
            })     
            
            .addCase(faqCreateAction.fulfilled, (state, action) => {
                state.faqCreateStatus = 'succeeded';
            })     
            
            

    },
});

export default faqSlice.reducer;







