import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const notificationCreateAction = createAsyncThunk(
    'notification/create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('notification/create',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

    export const resetNotificationtAction = createAction('support/reset');




    export const notificationListAction = createAsyncThunk(
        'notification/list', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get('notification/list')
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })



        export const notificationEditAction = createAsyncThunk(
            'notification/edit', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.put('notification/edit',reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })


            export const notificationDeleteAction = createAsyncThunk(
                'notification/delete', async (reqBody, thunkAPI) => {
                    try {
                        const response = await Api.delete(`notification/delete?id=${reqBody.id}`)
                        return response.data
                    } catch (error) {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                })
        
    

          