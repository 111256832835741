import { createSlice } from "@reduxjs/toolkit";
import { manageContentCreateAction, manageContentDetailAction, manageContentListAction, manageContentTopVideosListAction, manageContentUploadVideoAction, resetContentDetailAction, videoMonthCountAction, videoWeekCountAction } from "../Action/ManageContentAction";


const initialState = {
    createTeacherStatus:'idle',
    contentList:{},
    contentDetail:{},
    manageContentTopVideosList:[],
    videoMonthCountlist:{},
    videoWeekCountList:[],
    manageContentUploadVideoStatus:"idle"
}

const manageContentSlice = createSlice({
    name: 'manageContent',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageContentCreateAction.fulfilled, (state, action) => {
                state.createTeacherStatus = 'succeeded';
            })        

            .addCase(manageContentListAction.fulfilled, (state, action) => {
                state.contentList = action.payload.data
            })    
            
            .addCase(manageContentDetailAction.fulfilled, (state, action) => {
                state.contentDetail = action.payload.data
            })     
            
            

            .addCase(manageContentTopVideosListAction.fulfilled, (state, action) => {
                state.manageContentTopVideosList = action.payload.data
            })     
            

            .addCase(manageContentUploadVideoAction.fulfilled, (state, action) => {
                state.manageContentUploadVideoStatus = "success"
            })     
            

            
            .addCase(videoMonthCountAction.fulfilled, (state, action) => {
                state.videoMonthCountlist = action.payload.data
            })     
            
            .addCase(videoWeekCountAction.fulfilled, (state, action) => {
                state.videoWeekCountList = action.payload.data
            })     
            
            
            
            
            .addCase(resetContentDetailAction, () => initialState);

            
    
            
    },
});

export default manageContentSlice.reducer;




