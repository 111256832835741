import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const DashboardListAction = createAsyncThunk(
    'dashboard/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('/auth/dashboard')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const RegistrationGraphAction = createAsyncThunk(
        'dashboard/registration', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`/auth/data-visualization?type=${reqBody?.type}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    
    
    
    


    