import { createSlice } from "@reduxjs/toolkit";
import { listCategoryAction } from "../Action/categoryAction";


const categorySlice=createSlice({
    name:"Category",
    initialState:{
        category:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
         
          .addCase(listCategoryAction.fulfilled, (state, action) => {
            state.category=action?.payload?.data;
          })
  
  
          
         
      },

})

export default categorySlice.reducer;