import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";


export const ManageAdminListAction = createAsyncThunk(
    ' manageAdmin/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`sub/list?search=${reqBody.searchValue}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })





export const ManageAdmindetailsAction = createAsyncThunk(
    'manageAdmin/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/sub/details?id=${reqBody}`)

            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })




    export const LoginUserInfoAction = createAsyncThunk(
        'manageAdmin/info', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get('/profile/info')
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })


    export const ManageAdminCreateAction = createAsyncThunk(
        'manageAdmin/create', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post(`/sub/create`,reqBody)
    
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


        export const SubAdminSuspendAction = createAsyncThunk(
            'manageAdmin/suspend', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post(`/sub/suspend`,reqBody)
        
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
    
    


        

    export const ManageAdminEditAction = createAsyncThunk(
        'manageAdmin/edit', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.put(`/sub/edit`,reqBody)
    
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    
        export const resetManageAdminAction = createAction('manageAdmin/reset');


        export const ManageAdminDeleteAction = createAsyncThunk(
            'manageAdmin/delete', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.delete(`/sub/delete?id=${reqBody?.id}`)
        
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        

