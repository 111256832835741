import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const supportListAction = createAsyncThunk(
    'support/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('report/list')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

    export const resetsupportAction = createAction('support/reset');




    export const supportDetailAction = createAsyncThunk(
        'support/detail', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.get(`report/details?id=${reqBody?.id}`)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


        export const supportDetailStatusAction = createAsyncThunk(
            'support/status', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.put(`report/update?status=${reqBody?.status}&id=${reqBody?.id}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        
    
     

        
    

// export const supportDeleteAction = createAsyncThunk(
//     'support/delete', async (reqBody, thunkAPI) => {
//         try {
//             const response = await Api.delete(`report/delete?id=${reqBody?.id}`)
//             return response.data
//         } catch (error) {
//             return thunkAPI.rejectWithValue(error.response.data);
//         }
//     })




