import React, { Suspense, useState } from 'react';
import Sidebar from "./Sidebar";
import LoaderComponent from '../common/LoaderComponent';

const PrivateRoutesSuspense = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div>
        <div className="main-wrap">
          <div className={show ? "left-side hide" : "left-side "}>
            <Sidebar
              isActive={isActive}
              setIsActive={setIsActive}
              show={show}
              setShow={setShow}
            />
          </div>
          <Suspense fallback={
            <LoaderComponent />
          }>
            <div className={show ? "right-side-cmn hide" : "right-side-cmn "}>
              {children}
            </div>
          </Suspense>
        </div>
      </div>
    </>

  );
};

export default PrivateRoutesSuspense;
