import { createSlice } from "@reduxjs/toolkit";
import { tagsCreateAction, tagsListAction, tagsUpdateAction } from "../Action/TagsAction";


const initialState = {
   tags:{},
   faqCreateStatus:"idle",
   tagsUpdateStatus:"idle"
}

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(tagsListAction.fulfilled, (state, action) => {
                state.tags = action?.payload?.data;  
            })        

            .addCase(tagsCreateAction.fulfilled, (state, action) => {
                state.faqCreateStatus = 'succeeded';
            })        


            .addCase(tagsUpdateAction.fulfilled, (state, action) => {
                state.tagsUpdateStatus = 'succeeded';
            })        
            
    },
});

export default tagsSlice.reducer;






