import { createSlice } from "@reduxjs/toolkit";
import { teacheResetPassAction, teacherClassListAction, teacherCollectionListAction, teacherDeleteAction, teacherDetailAction, teacherListAction, teacherStudentIdListAction, teacherSuspendAction, weelklyLoginTeacherAction } from "../Action/TeacherAction";

const initialState = {
    teachers: {},
    teacherDetail: {},
    teacheResetPassStatus: "idle",
    teacherCollectionListStatus:"idle",
    teacherClassListStatus:"idle",
    teacherSuspendStatus:"idle",
    teacherDeleteStatus:"idle",
    weelklyLoginTeacherStatus:"idle",
    teacherStudentIdList:[]
}

const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(teacherListAction.fulfilled, (state, action) => {
                state.teachers = action?.payload?.data;
            })
            .addCase(teacheResetPassAction.fulfilled, (state, action) => {
                state.teacheResetPassStatus = 'succeeded';
            })
            .addCase(teacherDetailAction.fulfilled, (state, action) => {
                state.teacherDetail = action?.payload?.data;
            })
            .addCase(teacherCollectionListAction.fulfilled, (state, action) => {
                state.teacherCollectionListStatus  = 'succeeded';
            })
            .addCase(teacherClassListAction.fulfilled, (state, action) => {
                state.teacherClassListStatus  = 'succeeded';
            })
           
            .addCase(teacherSuspendAction.fulfilled, (state, action) => {
                state.teacherSuspendStatus  = 'succeeded';
            })


            .addCase(teacherDeleteAction.fulfilled, (state, action) => {
                state.teacherDeleteStatus  = 'succeeded';
            })

            .addCase(weelklyLoginTeacherAction.fulfilled, (state, action) => {
                state.weelklyLoginTeacherStatus  = 'succeeded';
            })



            

            .addCase(teacherStudentIdListAction.fulfilled, (state, action) => {
                state.teacherStudentIdList  = action?.payload?.data;

            })


            
            

            

            
            
    },
});

export default teacherSlice.reducer;