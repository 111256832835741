import { createSlice } from "@reduxjs/toolkit";
import { avatarAction, studentClassListAction, studentCollectionListAction, studentCreateAction, studentDeleteAction, studentEditAction, studentListAction, studentResetPassAction, studentSuspendAction, weelklyLoginStudentAction } from "../Action/studentAction";

const studentSlice = createSlice({
    name: "student",
    initialState: {
        student: [],
        studentResetPassStatus: "idle",
        studentCollectionListStatus:"idle",
        studentClassListStatus:"idle",
        studentSuspendStatus:"idle",
        studentDeleteStatus:"idle",
        weelklyLoginStudentStatus:"idle",
        studentCreateStatus:"idle",
        studentEditStatus:"idle",
        avatars:{}
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(studentListAction.fulfilled, (state, action) => {
                state.student = action?.payload?.data;
            })
            .addCase(studentResetPassAction.fulfilled, (state, action) => {
                state.studentResetPassStatus = 'succeeded';
            })
            .addCase(studentCollectionListAction.fulfilled, (state, action) => {
                state.studentCollectionListStatus = 'succeeded';
            })
          
            .addCase(studentClassListAction.fulfilled, (state, action) => {
                state.studentClassListStatus = 'succeeded';
            })

            
            .addCase(studentSuspendAction.fulfilled, (state, action) => {
                state.studentSuspendStatus = 'succeeded';
            })

            .addCase(studentDeleteAction.fulfilled, (state, action) => {
                state.studentDeleteStatus = 'succeeded';
            })

            
            .addCase(weelklyLoginStudentAction.fulfilled, (state, action) => {
                state.weelklyLoginStudentStatus = 'succeeded';
            })

            .addCase(studentCreateAction.fulfilled, (state, action) => {
                state.studentCreateStatus = 'succeeded';
            })    
            

            .addCase(studentEditAction.fulfilled, (state, action) => {
                state.studentEditStatus = 'succeeded';
            })    
            


            .addCase(avatarAction.fulfilled, (state, action) => {
                state.avatars = action?.payload?.data;
            })    
            
            
    }
})

export default studentSlice.reducer;