import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectPublic = () => {
  const localStorageToken = localStorage.getItem('token');
  const isVerified = JSON.parse(localStorage.getItem('isVerified'));

  return localStorageToken && isVerified ? <Navigate to='/dashboard' /> : <Outlet />;
};

export default ProtectPublic;
