import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";



export const createCategoryAction = createAsyncThunk(
    'category-create', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/category/create', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })



export const listCategoryAction = createAsyncThunk(
    'category-list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/category/list?search=${reqBody?.searchValue == undefined ? "" : reqBody?.searchValue}&page=${reqBody.page}&limit=${reqBody?.limit == undefined ? "" : reqBody?.limit}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export const updateCategoryAction = createAsyncThunk(
    'category-update', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/category/update', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

export const deleteAction = createAsyncThunk(
    'category-delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/category/delete?id=${reqBody?.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

